/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import NationsSeeker from 'components/NationsSeeker'

// Language
import { getLanguage } from 'services/language'

// Elements
import HeroAlt from 'components/elements/HeroAlt'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'
import ButtonAnimateTruck from 'components/elements/ButtonAnimateTruck'

import BackgroundDescription from 'components/elements/BackgroundDescription'
import ParseContent from 'components/shared/ParseContent'
import RatingAlt from 'components/Reviews/RatingAlt'

// Data
import homeInfo from 'src/graphql/homeInfo'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
    reviews,
    cta,
    ctaEn,
  },
}) => {
  const language = getLanguage()
  const homeFields = homeInfo.get()

  const ButtonDiv = styled.div`
    margin-top: 2rem !important;
  `

  const StyledRatingAlt = styled(RatingAlt)`
    margin-top: 2rem;
  `

  const StyledBlockDefault = styled(BlockDefault)`
    border: 2px solid #109820;
  `

  let review

  if (reviews) {
    review = reviews.edges
  }

  // if language === 'en_US' then cta = ctaEn
  const ctaLang = language === 'en_US' ? ctaEn : cta

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <section>
        <HeroAlt small image={acf.banner.image}>
          <HeroDescription className="d-flex align-items-center justify-content-start h-100">
            {parse(acf.banner.title)}
          </HeroDescription>
        </HeroAlt>
      </section>

      <section>
        <div className="container py-5 my-3">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row justify-content-end">
                <div className="col-lg-11 col-xl-10">
                  <RatingAlt className="pb-lg-0 pb-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {review &&
                review.map(({ node }, index) => {
                  if (index !== 0 && index % 10 === 0 && review.length > 10) {
                    return (
                      <>
                        <StyledBlockDefault
                          className="d-flex flex-wrap flex-xl-nowrap mb-5"
                          bigBorderRadius
                        >
                          <div>
                            <div>{parse(ctaLang.acf.cta_block.title)}</div>
                          </div>
                          <div>
                            <div>
                              {parse(ctaLang.acf.cta_block.description)}
                            </div>
                            <ButtonDiv className="mt-5 text-center">
                              <ButtonAnimateTruck
                                to={ctaLang.acf.cta_block.button.url}
                              >
                                {ctaLang.acf.cta_block.button.title}
                              </ButtonAnimateTruck>
                            </ButtonDiv>
                            <StyledRatingAlt noTitle />
                          </div>
                        </StyledBlockDefault>
                        <Review
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          fields={{
                            name: node.acf.name,
                            stars: node.acf.stars,
                            description: node.acf.description,
                            move_rule: node.acf.move_rule,
                          }}
                        />
                      </>
                    )
                  }

                  return (
                    <Review
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      fields={{
                        name: node.acf.name,
                        stars: node.acf.stars,
                        description: node.acf.description,
                        move_rule: node.acf.move_rule,
                      }}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-lg-0 mt-lg-5 pt-lg-0 pt-lg-5">
          <BackgroundDescription
            className="mt-lg-5 pt-lg-5 mt-lg-3"
            content={homeFields.acf.search.background_description}
          />
        </div>
      </section>

      <section>
        <div className="mt-lg-5 pt-lg-5 pb-5">
          <div className="container pt-lg-5 pt-0 mb-5 pt-lg-5">
            <NationsSeeker fields={homeFields.acf.search} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

const ReviewProfile = styled.div`
  & svg {
    width: 35px;
    height: 35px;

    & path,
    & circle {
      fill: #cccccc;
    }
  }
`

const ReviewStar = styled.div`
  & svg {
    & path {
      fill: #ffbe00;
    }

    @media (min-width: 992px) {
      width: 16px;
      height: 16px;
    }

    @media (max-width: 991px) {
      width: 11px;
      height: 11px;
    }
  }
`

const ReviewArrow = styled.div`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: #0c991f;
    }
  }
`

const Review = ({ className = '', fields = {} }) => {
  const stars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const rating = fields.stars

  return (
    <BlockDefault className={`${className} mb-5`}>
      <div className="row">
        <div className="col-lg-4">
          <div className="d-flex d-lg-block flex-wrap justify-content-between align-items-start">
            <div className="d-flex align-items-center">
              <ReviewProfile>
                <svg
                  height="512"
                  viewBox="0 0 32 32"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <path d="m16 17a6 6 0 1 1 6-6 6 6 0 0 1 -6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0 -4-4z" />
                    <path d="m16 31a15 15 0 0 1 -11.59-5.49l-.52-.64.52-.63a15 15 0 0 1 23.18 0l.52.63-.52.64a15 15 0 0 1 -11.59 5.49zm-9.49-6.12a13 13 0 0 0 19 0 13 13 0 0 0 -19 0z" />
                    <path d="m16 31a15 15 0 1 1 11.59-5.49 15 15 0 0 1 -11.59 5.49zm0-28a13 13 0 1 0 13 13 13 13 0 0 0 -13-13z" />
                    <path d="m5.18 24.88s10.07 11.25 20.32 1.12l1.32-1.12s-8.56-8.88-17.25-3.55z" />
                    <circle cx="16" cy="11" r="5" />
                  </g>
                </svg>
              </ReviewProfile>
              <div className="pl-3">{fields.name}</div>
            </div>
            <div className="d-flex mt-0 mt-lg-4 font-size-xm font-weight-l color-main">
              {Number(fields.stars).toFixed(1).replace('.0', '')} / 10
            </div>
            <div className="d-flex align-items-center ">
              {stars.map((star) => {
                let starType = 'empty'

                if (rating % 1 === 0.5 && Math.ceil(rating) === star) {
                  starType = 'half'
                } else if (star < Number(rating) || star === Number(rating)) {
                  starType = 'full'
                }

                return (
                  <ReviewStar key={star} className="mr-2">
                    {starType === 'empty' && (
                      <svg
                        height="511pt"
                        viewBox="0 -10 511.98685 511"
                        width="511pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0" />
                      </svg>
                    )}
                    {starType === 'full' && (
                      <svg
                        height="511pt"
                        viewBox="0 -10 511.99143 511"
                        width="511pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657" />
                      </svg>
                    )}
                    {starType === 'half' && (
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="475.044px"
                        height="475.044px"
                        viewBox="0 0 475.044 475.044"
                      >
                        <g>
                          <path
                            d="M474.487,183.276c-1.711-5.236-6.852-8.52-15.41-9.851l-143.323-20.839L251.52,22.681c-4-7.804-8.661-11.704-13.989-11.704
                      c-5.519,0-10.183,3.9-13.988,11.704l-64.241,129.905L15.978,173.425c-8.564,1.332-13.704,4.615-15.415,9.851
                      c-1.709,5.236,0.478,10.898,6.567,16.989l103.924,101.068L86.501,444.082c-0.95,6.286-0.381,11.173,1.715,14.702
                      c2.092,3.524,5.33,5.283,9.707,5.283c3.237,0,7.043-1.14,11.42-3.433l128.194-67.382l128.19,67.382
                      c4.377,2.286,8.186,3.433,11.423,3.433c4.381,0,7.622-1.759,9.709-5.283c2.088-3.529,2.659-8.416,1.708-14.702l-24.551-142.749
                      l103.63-101.068C473.93,194.174,476.212,188.512,474.487,183.276z M338.597,275.065l-13.99,13.421l3.43,18.843l17.128,101.357
                      l-90.786-47.965l-16.848-8.856V76.927l45.395,91.933l8.559,17.128l18.85,2.856l101.642,14.844L338.597,275.065z"
                          />
                        </g>
                      </svg>
                    )}
                  </ReviewStar>
                )
              })}
            </div>
            <div className="d-flex align-items-top mt-1 mt-lg-4 font-size-sm pt-2">
              <ReviewArrow>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 492.004 492.004"
                >
                  <g>
                    <g>
                      <path
                        d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
        c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
        v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
        c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
        l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"
                      />
                    </g>
                  </g>
                </svg>
              </ReviewArrow>
              <div className="pl-3">{fields.move_rule}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 mt-4 mt-lg-0">
          <ParseContent content={fields.description} />
        </div>
      </div>
    </BlockDefault>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
        }
      }
    }

    reviews: allWordpressWpReview(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          acf {
            name
            stars
            description
            move_rule
          }
        }
      }
    }

    cta: wordpressWpComponenten(wordpress_id: { eq: 4961 }) {
      title
      acf {
        cta_block {
          title
          description
          button {
            title
            url
          }
          info
        }
      }
    }

    ctaEn: wordpressWpComponenten(wordpress_id: { eq: 4970 }) {
      title
      acf {
        cta_block {
          title
          description
          button {
            title
            url
          }
          info
        }
      }
    }
  }
`

export default PageTemplate
